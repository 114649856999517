import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API } from './constants';
import Event from './pages/Event/Event';
import Live from './Live'; // Import the Events component
import Page from './Page';

  
  const fetchQr = (qr) =>{
  
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API}/qrcodes?value=${qr}`,
      headers: { }
    };
  
    return axios.request(config)
    .then((response) => {
      if(response?.data.length>0){
        let qrcode = response.data[0];
        return qrcode;    
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
    });
  
  }
  
  const Qr = () => {
    const { qr } = useParams(); // Get the dynamic parameter from the URL
    const [marker, setMarker] = useState(null);
    const [event, setEvent] = useState(null);
    const [page, setPage] = useState(null);
    
  
     useEffect(() => {
      if (qr) {
        document.title = `ISYW QR : ${qr}`;

        fetchQr(qr).then(qrcode => {
          if(qrcode.marker) setMarker(qrcode.marker);
          if(qrcode.event) setEvent(qrcode.event);
          if(qrcode.page) setPage(qrcode.page);
        });        
      }
      }, [qr]);
  
      if(event) return <Event _id={event.id}></Event>
      if(marker) return <Live _id={marker.id}></Live>
      if(page) return <Page slug={page.slug}></Page>
      return <></>
  };
  
  export default Qr;
  