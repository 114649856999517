import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents, Popup, Tooltip, Icon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { API } from './constants';
import { getIcon, getToken } from './helpers';

const fetchMarkersWithinBounds = (bounds) =>{

  const min_lat = bounds.southWest.lat;
  const max_lat = bounds.northEast.lat;
  const min_lng = bounds.southWest.lng;
  const max_lng = bounds.northEast.lat;
  
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${API}/markers?_where[latitude_gt]${min_lat}&_where[latitude_lt]=${max_lat}&_where[longitude_gt]=${min_lng}&_where[longitude_lt]=${max_lng}`,
    headers: { }
  };

  return axios.request(config)
  .then((response) => {
    let cameras = response.data;
    return cameras;
  })
  .catch((error) => {
    console.log(error);
  });

}

const MapComponent = () => {
  const [center, setCenter] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [markers, setMarkers] = useState(null);

  const map = useMap(); // Access the Leaflet map instance
  
  useEffect(() => {
    // Function to get and set center and bounds whenever the map moves
    const updateMapInfo = () => {
      const mapCenter = map.getCenter();
      setCenter({
        lat: mapCenter.lat,
        lng: mapCenter.lng,
        zoom: map.getZoom()
      });

      const mapBounds = map.getBounds();
      setBounds({
        northEast: mapBounds.getNorthEast(),
        southWest: mapBounds.getSouthWest(),
      });
    };

    // Call updateMapInfo on map moveend or zoomend
    map.on('moveend', updateMapInfo);
    map.on('zoomend', updateMapInfo);

    // Initial map info load
    updateMapInfo();

    // Cleanup event listeners on unmount
    return () => {
      map.off('moveend', updateMapInfo);
      map.off('zoomend', updateMapInfo);
    };
  }, [map]);

  useEffect(() => {
    if (center && bounds) {
      console.log('Center:', center);
      console.log('Bounds:', bounds);

      // Make an API call using the center or bounds data to fetch markers
      // For example, call an API to get markers within the bounds:
      fetchMarkersWithinBounds(bounds).then(markers => {
        setMarkers(markers);
      });
      ;
    }
  }, [center, bounds]);

  return !markers 
  ? null 
  : markers.map(marker => {
    const {id, name, address, type, photo, url} = marker;
    const lat_lng = { 
      lat: marker.latitude, 
      lng: marker.longitude 
    };    
    
    const thumbnail = photo && photo.formats ? `${API}${photo.formats.thumbnail.url}`:null;
    
    return (
    <Marker 
      icon={getIcon(marker.type)}
      alt={marker.type}
      key={id} 
      position={lat_lng} 
      className={type}>
      <Popup>
      {name?(<h3>{name}</h3>):null}     
      <ul>
        <li><Link to={"/events/" + id}>List events</Link></li>
        {getToken()?(<li><Link to={"/addevent/" + id}>Add new event</Link></li>):null}
        {url ? (<li><Link to={"/live/" + id}>Watch Live Stream</Link></li>) : null}

        
      </ul>
      {address?(<p>{address}</p>):null}     
      {thumbnail?(<img className="popup-thumbnail" src={thumbnail} alt={name}></img>):null}


      {getToken()?null:(<p><Link to={"/signin"}>Sign in to register events</Link></p>)}
      </Popup>
      {type?(<Tooltip>{type}</Tooltip>):null}
    </Marker>
  )}
  )
};

const LocationMarker = () => {
    const [position, setPosition] = useState(null)

    useEffect(() => {   
         map.locate()
      }, []);

    const map = useMapEvents({
    //   click() {
    //     map.locate()
         //   alert ("add camera");
    //   },

      locationfound(e) {
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      },
    })
  
    return null;
    // return position === null ? null : (
    //   <Marker 
    //     position={position} 
    //     icon={getIcon('location')}
    //   ></Marker>
    // )
  }

const InteractiveMap = ({latitude, longitude}) => {
  const [markers, setMarkers] = useState([]);  

  const AddMarkerOnClick = () => {
    const [marker, setMarker] = useState(null);
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setMarker({ lat, lng });
      },
    });
    return marker ? (
      <Marker 
        position={[marker.lat, marker.lng]}
        icon={getIcon('location')}
        >
          {getToken()?(
        <Popup>
          <p>Add a new camera at this location?</p>
          <Link to={`/addCamera/${marker.lat}/${marker.lng}`}>Add Camera</Link>
        </Popup>):null}
      </Marker>
    ) : null;
  };


  return (
    <MapContainer 
    center={[latitude, longitude]} 
    zoom={13} 
    style={{ height: '100vh', width: '100%' }}
    scrollWheelZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution=""
      />
      {markers.map((marker, index) => (
        <Marker 
          icon={getIcon(marker.type)}
          alt={marker.type}
          key={index} 
          position={[marker.lat, marker.lng]} 
        >
          <Popup>
          {marker.name?(<h3>{marker.name}</h3>):null}     
          {marker.address?(<p>{marker.address}</p>):null}
            {marker.thumbnail?(
              <img className="popup-thumbnail" src={marker.thumbnail} alt={marker.name}></img>
            ):null}
          </Popup>
          {marker.type?(<Tooltip>{marker.type}</Tooltip>):null}
        </Marker>
      ))}
      <AddMarkerOnClick />
      <LocationMarker />
      <MapComponent />
    </MapContainer>
  );
};

export default InteractiveMap;
