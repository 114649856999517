 
import { API, AUTH_TOKEN } from "./constants";
import L from 'leaflet';
import MarkdownIt from 'markdown-it';
import DOMPurify from 'dompurify';

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const replaceMarkdownImages = (text) => {
  if(!text) return <></>;
  // Regular expression to match the markdown image format
  const md = new MarkdownIt();
  
  const htmlContent = md.render(text).replaceAll('<img src="',`<img class="content-image" src="${API}`);
  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
    />
  );

};

export const getIcon = (type) => {
  return new L.Icon({
    iconUrl: '/marker-icon-2x-'+type+'.png', // Path to your custom marker image
  });
};


export const getCountryAndCity = async (latitude,longitude) => {
  const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
  const data = await response.json();
  const city = data?.address?.county || data?.address?.city || data?.address?.town;
  const country = data?.address?.country;
  return {country,city};
}