import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Card, Col, Form, Row , message, Input, Button, Spin, Select, Upload} from 'antd';
import useScreenSize from "./hooks/useScreenSize";
import TextArea from "antd/es/input/TextArea";
import { getCountryAndCity, getToken } from './helpers';
import { API } from './constants';




const AddCamera = () => {
  const [form] = Form.useForm();

  const { isDesktopView } = useScreenSize();

  const { lat, lng } = useParams();
  const [type, setType] = useState('camera');
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { file, setFile } = useState(null);

  const props = {
    multiple: false,
    listType: 'picture',
    accept: '.jpg,.jpeg,image/*',
    maxCount: 1,
    beforeUpload: file => {
      return false;
    },
    file,
  };

  const validateFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.size > 200 * 1024) {
      setError('File size exceeds 200KB limit');
    } else {
      setError(null);
    }
  };

  const handleChange = (e) => {
    
  };

  const onFinish = async (values) => {
    try{

      setIsLoading(true);
      setError(null);      

    const geodata = await getCountryAndCity(lat,lng);

    let markerData = {
      latitude: lat,
      longitude: lng,
      name: values.name,
      type: values.type,
      url: values.url,
      address: values.address,
      description: values.description,
      country: geodata?.country,
      city: geodata?.city,
    };

    const file = values.photo?.file;
    if (file){
        if(file.size > 500 * 1024) {
        setError('File size exceeds 200KB limit');
      } else {
        setError(null);
        
        const formData = new FormData();
        formData.append('files', file);

        try {
          const uploadResponse = await axios.post(`${API}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          markerData.photo = uploadResponse.data[0].id;

        } catch (err) {
          console.error('Error uploading file', err);
          setError('Failed to upload file. Please try again.');
        }
      }
    }

        await axios.post( `${API}/markers`, markerData, {
          headers: {
            'Content-Type': 'application/json' ,
            Authorization: `Bearer ${getToken()}`,
          },
        });

      message.success("Camera added successfully!");
      } catch (error) {
          console.error(error);
          setError(error?.message ?? "Something went wrong!");
        } finally {
          setIsLoading(false);
          form.resetFields();
        }


  };

  return (

    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Add a new camera">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              onChange={handleChange}
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Name"/>
              </Form.Item>

              <Form.Item
                label="Type"
                name="type"
                rules={[{ required: false }]}
                defaultValue="camera"
              >
                  <Select 
                    defaultValue="camera"
                    onChange={(type) => setType(type)}>
                  <Select.Option value="camera">Camera</Select.Option>
                  <Select.Option value="intervention">Intervention</Select.Option>
                </Select>
              </Form.Item>


              <Form.Item
                label="Address"
                name="address"
                rules={[{ required: false }]}
              >
                <TextArea placeholder="Address" />
                </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: false }]}
              >
                <TextArea placeholder="Description" />
                </Form.Item>

            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: false,
                  type: "url",
                },
              ]}
            >
              <Input placeholder="Stream URL" />
            </Form.Item>

            <Form.Item
            label="Photo"
            name="photo"
            rules={[{required: false}]}
            >
                <Upload  {...props}>
                  <Button>
                    Select image
                  </Button>
                </Upload>
            </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Save {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>

            </Form>
          </Card>
        </Col>
      </Row>
    </Fragment>

    // <div>
    //   <h2>Add New Camera</h2>
    //   <form onSubmit={handleSubmit}>
    //     <div>
    //       <label>Type: </label><br/>
    //       <select value={type} onChange={(e) => setType(e.target.value)}>
    //         <option value="camera">Camera</option>
    //         <option value="intervention">Intervention</option>
    //         <option value="location">Location</option>
    //         <option value="live">Live</option>
    //       </select>
    //     </div><br/>
    //     <div>
    //       <label>Address: </label><br/>
    //       <textarea value={address} onChange={(e) => setAddress(e.target.value)} />
    //     </div><br/>
    //     <div>
    //       <label>Photo (max 200KB): </label><br/>
    //       <input type="file" accept="image/*" onChange={validateFileChange} />
    //     </div><br/>
    //     {error && <p style={{ color: 'red' }}>{error}</p>}
    //     <button type="submit" >Submit</button>
    //   </form>
    // </div>
  );
};

export default AddCamera;
