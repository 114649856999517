import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { replaceMarkdownImages } from './helpers';
import { Layout } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import { API } from './constants';
import { Helmet } from 'react-helmet';

const fetchPage = (slug) =>{

    let url = `${API}/pages?_where[slug]=${slug}`;
    
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url,
      headers: { }
    };
  
    return axios.request(config)
    .then((response) => {
      let page = response.data[0];
      return page;
    })
    .catch((error) => {
      console.log(error);
    });
  
  }
  
const Page = ({slug}) => {
  const  params  = useParams(); // Get the dynamic parameter from the URL
  slug = slug?slug:params?.slug;
  
  const [page, setPage] = useState(null);
   useEffect(() => {
      if (slug) {       
        fetchPage(slug).then(page => {
          page.content = replaceMarkdownImages(page.content);

          document.title = `ISYW : ${page.title}`;

          setPage(page);
        });        
      }
    }, [slug]);

  return <Layout>

        <Helmet>
        <meta name="description" content={page?.content} />
        <meta name="keywords" content="public space surveillance, performance, art, surveillance, reclaiming, activism"/>
        <meta name="author" content="Smaller than a pixel"/>
        <meta name="robots" content="index, follow"/>
    
        <meta property="og:title" content={page?.title}/>
        <meta property="og:description" content={page?.content} />
        <meta property="og:image" content="https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"/>
        <meta property="og:url" content="https://map.smallerthanapixel.com"/>
        <meta property="og:type" content="website"/>
        <meta property="og:site_name" content={page?.title}/>
    
        <meta name="twitter:card" content="https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"/>
        <meta name="twitter:title" content={page?.title}/>
        <meta name="twitter:description" content={page?.content} />
        <meta name="twitter:image" content="https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"/>
        <meta name="twitter:site" content="@smallerthanapixel"/>

            <script type="application/ld+json">
            { JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": page?.title,
              "description": page?.content,      
              "url": window.location.href,
              "image": "https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"
            }) }
        
            </script>

        </Helmet>
    <Header
      style={{
        height: '300px',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url("${API}${page?.header?.formats?.small?.url}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',  
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',          
        flexDirection: 'column', // Stack items vertically
      }}>
        <Title
                  level={1}
                  style={{
                    color: 'white',
                    fontSize: '3rem',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    marginBottom: '8px',
                  }}
                  >
          {page?.title}
        </Title>
        </Header>
        <Content
                style={{
                  padding: '24px',
                  backgroundColor: '#f0f2f5',
                }}
                >
            <div
              style={{
              maxWidth: '100%',
              margin: '0 auto',
              backgroundColor: 'white',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {page?.content}
          </div>
      </Content>
    </Layout>
};

export default Page;
