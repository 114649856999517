import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import './index.css';
import App from './App';
import Events from './Events'; // Import the Events component
import Live from './Live'; // Import the Events component
import Contact from './Contact'; // Import the Events component
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Page from './Page';
import AuthProvider from "./components/AuthProvider/AuthProvider";
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import { getToken } from './helpers';
import Profile from './components/Profile/Profile';
import AppHeader from './components/Appheader/Appheader';
import { Button } from 'antd';
import Event from './pages/Event/Event';
import Cameras from './Cameras';
import AddEvent from './AddEvent';
import AddCamera from './AddCamera';
import Qr from './Qr';

const Layout = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

  return (
    <div>
      <header>
        <nav>
          <div className="menu-toggle" onClick={toggleMenu}>
            ☰ {/* Hamburger icon */}
          </div>
          <ul className={isOpen ? "nav-links show" : "nav-links"}>
          <Button className="header_space_brand" href="/map" type="link">Map</Button>
          <Button className="header_space_brand" href="/cameras" type="link">Cameras</Button>
          <Button className="header_space_brand" href="/events" type="link">Events</Button>
          <Button className="header_space_brand" href="/p/about" type="link">About</Button>
          <Button className="header_space_brand" href="/contact" type="link">Contact</Button>

            <AppHeader></AppHeader>
          </ul>
        </nav>
      </header>
      <main className='content'>{children}</main>
    </div>
  );
};

const Root = () => (  
  <Router>
    <Layout>
      <Routes>
      <Route path="/" element={<Page slug="about"/>} />
      <Route path="/map" element={<App />} />
      <Route path="/events" element={<Events />} />
        <Route path="/cameras" element={<Cameras />} />
        <Route path="/events/:marker" element={<Events />} />
        <Route 
          path="/addEvent/:marker" 
          element={getToken() ? <AddEvent /> : <Navigate to="/signin" />
        }/>
        <Route path="/event/:id" element={<Event />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/live/:id" element={<Live />} />
        <Route path="/qr/:qr" element={<Qr />} />
        <Route path="/p/:slug" element={<Page />} />
        <Route 
          path='/addCamera/:lat/:lng' 
          element={getToken() ? <AddCamera /> : <Navigate to="/signin" />}
        />
        <Route path='/map/:lat/:lng' element={<App />} />

        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        
        <Route
            path="/profile"
            element={getToken() ? <Profile /> : <Navigate to="/signin" />}
          />

      </Routes>
    </Layout>
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <AuthProvider>
      <Root />
    </AuthProvider>
  </React.StrictMode>
);

// Service worker for PWA support
serviceWorkerRegistration.unregister();

// Performance measurement
reportWebVitals();
