import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tabs, Input, Card, Row, Col, Spin, Button, Tag, Switch, Select } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { replaceMarkdownImages } from "./helpers";
import { API } from "./constants";
import { Option } from "antd/es/mentions";

const { TabPane } = Tabs;
const { Search } = Input;

const Cameras = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [cameras, setCameras] = useState({
    all: [],
    nearme: [],
  });
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [city, setCity] = useState(""); // City filter
  const [country, setCountry] = useState(""); // Country filter
  const [cities, setCities] = useState([]); // List of cities
  const [countries, setCountries] = useState([]); // List of countries
 
  // Fetch cameras from the API
  const fetchCameras = async (page, country,city) => {
    try {

      const response = await axios.get(`${API}/markers`, {
        params: {
          _limit: 100,
          _start: (page - 1) * 10,
          city: city || undefined, // Pass city filter if selected
          country: country || undefined, // Pass country filter if selected
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching cameras:", error);
      return [];
    }
  };

    // Fetch cities and countries for filters
    const fetchFilters = async (country) => {
      try {

        const response = await axios.get(`${API}/markers`, {
          params: {          
            country: country || undefined, // Pass country filter if selected
          }
        });
        const cities = response.data.map(m=>m.city).filter(m=>m);
        setCities([...new Set(cities)]);
        if(!country) {
          const countries = response.data.map(m=>m.country).filter(m=>m);
          setCountries([...new Set(countries)]); 
        }
      } catch (error) {
        console.error("Error fetching filters:", error);
      }
    };

    useEffect(() => {
      fetchFilters(country);
    }, [country]);

  // Load initial cameras and handle search
  useEffect(() => {
    (async () => {
      const newCameras = await fetchCameras(1,country,city);      
      setCameras((prev) => ({
        ...prev,
        ['all']: newCameras
        .filter((obj, index, self) =>
          index === self.findIndex((item) => item.id === obj.id)),
      }));
      setPage(2);
    })();
  }, [searchTerm, city, country]);

  // Load more cameras for infinite scroll
  const loadMoreCameras = async () => {
    const newCameras = await fetchCameras(page,country,city);
    setCameras((prev) => ({
      ...prev,
      ['all']: [...prev['all'], ...newCameras].filter((obj, index, self) =>
        index === self.findIndex((item) => item.id === obj.id))
      
      ,
    }));
    setPage((prev) => prev + 1);

    if (newCameras.length < 10) {
      setHasMore(false);
    }
  };

  // Render Camera Cards
  const renderCameraCards = (cameraList) =>
    cameraList.map((camera) => { 
      const description =  replaceMarkdownImages(camera.description?camera.description:'', false);

      const imageUrl = camera.photo?.formats?.medium?.url
      ? `${API}${camera.photo.formats.medium.url}`
      : null;
      
      return ( <Col key={camera.id} xs={24} sm={12} md={8} lg={6}>
        <div
          className="camera-card-wrapper"
          style={{
            marginBottom: 16,
            overflow: "hidden", // Ensures content stays inside card boundaries
          }}
        >
        <Card
          hoverable
          style={{
            marginBottom: 16,
            backgroundImage: imageUrl
              ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4)), url(${imageUrl})`
              : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "#fff",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)", // Improves text readability
            transition: "background-image 0.3s ease", // Smooth transition effect           

          }}
          styles={{body:{
            backgroundColor: "transparent",
            padding: "16px",
          }}}
          onClick={() => window.location=`/live/${camera.id}`}
        >
          <h3>{camera.name || "Camera"}</h3>

          <p>{description || "No description available"}</p>
        </Card>
        </div>
      </Col>)
  });

  return (
    <div style={{ padding: "16px" }}>
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
      <Col xs={24} sm={12} md={6}>
          <Select
            placeholder="Select Country"
            style={{ width: "100%" }}
            value={country}
            onChange={(value) => setCountry(value)}
            allowClear
          >
            {countries.map((c) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        </Col>
        {/* City Filter */}
        <Col xs={24} sm={12} md={6}>
          <Select
            placeholder="Select City"
            style={{ width: "100%" }}
            value={city}
            onChange={(value) => setCity(value)}
            allowClear
          >
            {cities.map((c) => (
              <Option key={c} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

          <InfiniteScroll
            dataLength={cameras.all.length}
            next={loadMoreCameras}
            hasMore={hasMore}
            loader={<Spin style={{ display: "block", margin: "20px auto" }} />}
            endMessage={<p style={{ textAlign: "center" }}>No more cameras to show</p>}
          >
            <Row gutter={[16, 16]}>{renderCameraCards(cameras.all)}</Row>
          </InfiniteScroll>
        
    </div>
  );
};

export default Cameras;
