import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert, Card, Col, Form, Row , message, Input, Button, Spin, TimePicker, DatePicker, Upload} from 'antd';
import useScreenSize from "./hooks/useScreenSize";
import TextArea from "antd/es/input/TextArea";
import { getToken } from './helpers';
import { API } from './constants';
import moment from 'moment';

const AddEvent = () => {
  const [form] = Form.useForm();

  const { isDesktopView } = useScreenSize();

  const { marker } = useParams();
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { file, setFile } = useState(null);

  const props = {
    multiple: false,
    listType: 'picture',
    accept: '.jpg,.jpeg,image/*',
    maxCount: 1,
    beforeUpload: file => {
      return false;
    },
    file,
  };


  const onFinish = async (values) => {
    try{

      setIsLoading(true);
      setError(null);      

    let eventData = {
      marker,
      title: values.title,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
      startTime: moment(values.startTime).format("HH:mm:00"),
      endTime: moment(values.endTime).format("HH:mm:00"),
      email: values.email,
      summary: values.summary,
      description: values.description,
    };

    console.log(eventData);
    
    
    const file = values.image?.file;
    if (file) {
      if(file.size > 500 * 1024) {
        setError('File size exceeds 200KB limit');
      } else {
        setError(null);
        
        const formData = new FormData();
        formData.append('files', file);

        try {
          const uploadResponse = await axios.post(`${API}/upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          eventData.image = uploadResponse.data[0].id;

        } catch (err) {
          console.error('Error uploading file', err);
          setError('Failed to upload file. Please try again.');
        }
      }
    }


      await axios.post( `${API}/events`, eventData, {
        headers: {
          'Content-Type': 'application/json' ,
          Authorization: `Bearer ${getToken()}`,
        },
      });

      message.success("Event added successfully!");
      } catch (error) {
          console.error(error);
          setError(error?.message ?? "Something went wrong!");
        } finally {
          setIsLoading(false);
          form.resetFields();
        }


  };

  return (

    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Add a new event">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true }]}
              >
                <Input placeholder="Title"/>
              </Form.Item>             


              <Form.Item  style={{ marginBottom: 0, color:"#000 !important"}}>
                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                label="Start date"
                name="startDate"
                rules={[{ required: false }]}
              >
                  <DatePicker format="YYYY/MM/DD" />
                </Form.Item>

                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                label="Start time"
                name="startTime"
                rules={[{ required: false }]}
              >
                <TimePicker minuteStep={5} defaultOpenValue={moment('00:00', 'HH:mm')} format="HH:mm"/>,
                </Form.Item>
                </Form.Item>


                <Form.Item  style={{ marginBottom: 0 }}>
                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                label="End date"
                name="endDate"
                rules={[{ required: false }]}
              >
                  <DatePicker format="YYYY/MM/DD"/>
                </Form.Item>

                <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)'}}
                label="End time"
                name="endTime"
                rules={[{ required: false }]}
              >
                <TimePicker minuteStep={5} defaultOpenValue={moment('00:00', 'HH:mm')} format="HH:mm"/>,
                </Form.Item>

                </Form.Item>

              <Form.Item
                label="Summary"
                name="summary"
                rules={[{ required: true }]}
              >
                <TextArea placeholder="Summary" />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true }]}
              >
                <TextArea placeholder="Description" />
                </Form.Item>


            <Form.Item
              label="Contact"
              name="email"
              rules={[
                {
                  required: false,
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Contact email" />
            </Form.Item>

       <Form.Item
            label="Image"
            name="image"
            rules={[{required: false}]}
            >
                <Upload  {...props}>
                  <Button>
                    Select image
                  </Button>
                </Upload>
            </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Save {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>

            </Form>
          </Card>
        </Col>
      </Row>
    </Fragment>

    // <div>
    //   <h2>Add New Event</h2>
    //   <form onSubmit={handleSubmit}>
    //     <div>
    //       <label>Type: </label><br/>
    //       <select value={type} onChange={(e) => setType(e.target.value)}>
    //         <option value="event">Event</option>
    //         <option value="intervention">Intervention</option>
    //         <option value="location">Location</option>
    //         <option value="live">Live</option>
    //       </select>
    //     </div><br/>
    //     <div>
    //       <label>Address: </label><br/>
    //       <textarea value={address} onChange={(e) => setAddress(e.target.value)} />
    //     </div><br/>
    //     <div>
    //       <label>Photo (max 200KB): </label><br/>
    //       <input type="file" accept="image/*" onChange={validateFileChange} />
    //     </div><br/>
    //     {error && <p style={{ color: 'red' }}>{error}</p>}
    //     <button type="submit" >Submit</button>
    //   </form>
    // </div>
  );
};

export default AddEvent;
