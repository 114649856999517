import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { getIcon, getToken, replaceMarkdownImages } from './helpers';
import { API } from './constants';
import { Layout, Tag } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Helmet } from 'react-helmet';

const fetchMarker = (id) =>{

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${API}/markers/${id}`,
    headers: { }
  };

  return axios.request(config)
  .then((response) => {
    let marker = response.data;
    return marker;
  })
  .catch((error) => {
    console.log(error);
  });

}

const Page = ({_id}) => {
  const { id } = useParams(); // Get the dynamic parameter from the URL
  const [marker, setMarker] = useState(null);

   useEffect(() => {
    _id = _id?_id:id;
    if (_id) {
       
      fetchMarker(_id).then(marker => {
        if(marker?.description){
          marker.description = replaceMarkdownImages(marker?.description);
        }
        document.title = `ISYW Camera : ${marker.name}`;

        setMarker(marker);
      });        
    }
    }, [_id,id]);

  return marker?<Layout>
    <Helmet>
    <meta name="description" content="This website has been created with the aim to reclaim and repurpose surveillance cameras in public spaces, enabling artists and performers to livestream, document and share their public space works. The platform also aims to encourage and inspire more artists to use public spaces for their works and serve as a hub that can spark collaboration among different creatives. Artists can choose a camera to create and share their events, information about their project and their own art practice." />
    <meta name="keywords" content="public space surveillance, performance, art, surveillance, reclaiming, activism"/>
    <meta name="author" content="Smaller than a pixel"/>
    <meta name="robots" content="index, follow"/>

    <meta property="og:title" content="I SEE YOU WATCHING"/>
    <meta property="og:description" content="This website has been created with the aim to reclaim and repurpose surveillance cameras in public spaces, enabling artists and performers to livestream, document and share their public space works. The platform also aims to encourage and inspire more artists to use public spaces for their works and serve as a hub that can spark collaboration among different creatives. Artists can choose a camera to create and share their events, information about their project and their own art practice." />
    <meta property="og:image" content="https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"/>
    <meta property="og:url" content="https://map.smallerthanapixel.com"/>
    <meta property="og:type" content="website"/>
    <meta property="og:site_name" content="I SEE YOU WATCHING"/>

    <meta name="twitter:card" content="https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"/>
    <meta name="twitter:title" content="I SEE YOU WATCHING"/>
    <meta name="twitter:description" content="This website has been created with the aim to reclaim and repurpose surveillance cameras in public spaces, enabling artists and performers to livestream, document and share their public space works. The platform also aims to encourage and inspire more artists to use public spaces for their works and serve as a hub that can spark collaboration among different creatives. Artists can choose a camera to create and share their events, information about their project and their own art practice." />
    <meta name="twitter:image" content="https://api.stap.n2048.com/uploads/Whats_App_Image_2025_01_22_at_16_07_36_075dc9388b.jpeg"/>
    <meta name="twitter:site" content="@smallerthanapixel"/>
    </Helmet>
      <Header
      style={{
        height: '300px',
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url("${API}${marker?.photo?.formats?.small?.url}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',          
        flexDirection: 'column', // Stack items vertically

      }}>
        <Title
                  level={1}
                  style={{
                    color: 'white',
                    fontSize: '3rem',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    marginBottom: '8px',
                  }}
                  >
          {marker?.name}
        </Title>
        </Header>
        <Content
                style={{
                  padding: '24px',
                  backgroundColor: '#f0f2f5',
                }}
                >
            <div
            style={{
              maxWidth: '100%',
              margin: '0 auto',
              backgroundColor: 'white',
              padding: '24px',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <section>
              {marker?.description}
            </section>
            <section>
            <div className="iframe-container">
            <iframe allow="fullscreen" allowFullScreen src={`${marker?.url}`}></iframe>
            </div>
            </section>
            
     
   <br/>
     
     <section>
     <Tag color="black"><Link to={"/events/" + marker.id}>Find other events on this location</Link></Tag>
    {getToken()?(<Tag color="green"><Link to={"/addevent/" + marker.id}>Plan a new event for this location</Link></Tag>):null}
    </section>
    <br/>

<section>
      <MapContainer 
          center={[marker.latitude, marker.longitude]} 
          zoom={18} 
          style={{ height: '400px', width: '99%' }}
          scrollWheelZoom={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="STAP"
            />
              <Marker                 
                // icon={getIcon('location')}
                icon={getIcon(marker.type)}
                alt={marker.name}
                key={marker.id} 
                position={[marker.latitude, marker.longitude]} 
              >
                <Popup>
                {marker && marker.photo && marker.photo.formats ? 
                    <img className="popup-thumbnail" 
                      src={`${API}${marker.photo.formats.thumbnail.url}`} 
                      alt={marker?.name}></img>
                      :<>{marker.type}</>}
                </Popup>
              </Marker>
          </MapContainer>
          </section>

          {marker.source?<section>
                <p>
                  <Tag color="blue">
                    <Link to={marker.source} target="_blank" rel="noopener noreferrer" >Open original stream source.</Link>
                  </Tag>
                </p>
              </section>:null} 
          </div>
      </Content>
      </Layout>:<></>;
};

export default Page;
